import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';


let $slider = $( 'div[is=hero-slider] .slider' ),
    options = {
        slide: '.slide',
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        speed: 1200,
        arrows: true,
        touchThreshold: 20,
        mobileFirst: true,
        rows: 0,
    };


if( $slider.data( 'autoplay' ) ) {
    options.autoplay = $slider.data( 'autoplay' );
}

if( $slider.data( 'autospeed' ) ) {
    options.autoplaySpeed = $slider.data( 'autospeed' );
}

if( $slider.data( 'infinte' ) ) {
    options.infinite = $slider.data( 'infinite' );
}else {
    options.infinite = false;
}

$slider.slick(options);

$(document).ready(function() {
    $('.go-to').click(function() {
        let target = $('[is="hero-slider"]');
        $('html, body').animate({
            scrollTop: target.offset().top + target.height() - 100
        }, 1200);
        return false;
    });

    $('.slide-play img').click(function() {
        $(this).parent().addClass('hide');
        player.play();
        setTimeout(() => {
            $(this).parent().parent().removeClass('paused');
        }, 1000);        
    });

    $('.slide-mute').click(function() {
        if($(this).hasClass('off')){
            $(this).removeClass('off');
            $(this).addClass('on');
        }else{
            $(this).addClass('off');
            $(this).removeClass('on');
        }
        player.toggleMuted();
    });

    var slidesVideo = document.querySelectorAll('.slide-video'), i;

	for (i = 0; i < slidesVideo.length; ++i) {
		slidesVideo[i].addEventListener('video_end', function(){
            $(this).parent().find('.slide-play').removeClass('hide');
            $(this).parent().addClass('paused');
        });
	}

    $('.hero-slider .slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if($('.slide-video').length){
            if(player.paused == false){
                player.pause();
                player.seek('0');
                $('.slide-play').removeClass('hide');
                setTimeout(() => {
                    $('.slide-hasVideo').addClass('paused');
                }, 3000);            
            }
            $('.slide-hasVideo').addClass('paused');
        }        
    });

    if($('.hero-slider .slick-current').hasClass('slide-hasVideo')) {
        setTimeout(() => {
            $('.hero-slider .slick-current .slide-play img').click();
        }, 1000);
    }
});