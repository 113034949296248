import $ from 'jquery';

import matchHeight from "jquery-match-height";



$(window).on( 'load', function () {

    if( $( '.same-height' ).length ) {
        let matchHeightOptions = {
            byRow: true,
            property: 'height',
            target: null,
            remove: false,
        };
    
        $( '.same-height' ).matchHeight(matchHeightOptions);
    }

    if($('.product-block').length){        
        $('.product-block').each(function() {

            var elemenetSidebySide = $(this);
            if(elemenetSidebySide.find('.image').hasClass('right') && elemenetSidebySide.find('.product-block__figure').hasClass('strech-lg-right')){
                var position = elemenetSidebySide.find('.image').offset();
                elemenetSidebySide.find('.product-block__figure').css('left',position.left);
            }
        });

        $(window).resize(function() {
            $('.product-block').each(function() {
                var elemenetSidebySide = $(this);
                if(elemenetSidebySide.find('.image').hasClass('right') && elemenetSidebySide.find('.product-block__figure').hasClass('strech-lg-right')){
                    var position = elemenetSidebySide.find('.image').offset();
                    elemenetSidebySide.find('.product-block__figure').css('left',position.left);
                }
            });
        });
    }
    
});

$(window).on('resize scroll load', function() {
    $('.product-block__image_ani').each(function() {
        var $element = $(this);

        if ($element.isInViewport()) {
            $element.addClass('onView');
        }
    });
});