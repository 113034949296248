import $ from 'jquery';
import '../../assets/scripts/simple-lightbox.min';
import '../../assets/scripts/simple-lightbox.min.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';


let gallery = new SimpleLightbox('.gallery-list__item a');

$('.gallery-loadMore').on('click',function(e){
	e.preventDefault()
	
	let $loadMore = $(this);
	let $grid = $('.gallery-grid');
	let $numPage = $(this).data('page');
	let $url = $(this).data('url') + '?num-page=' + $numPage + '';
	console.log($url);

	$loadMore.hide();
	$grid.css('opacity','0.5');
  
	$.ajax({
	  url: $url
	}).then(
	  response => {
		let $html = $(response);
		let $newImage = $('.gallery-list__item', $html);
		let $newPage = $('.gallery-loadMore', $html).data('page');
		console.log($newPage);
		if($newPage != '0'){
			$loadMore.data('page',$newPage);
			$loadMore.show();
		}else{
			$loadMore.remove();
		}
  
		$('.gallery-grid').append($newImage);
		$grid.css('opacity','1');
		gallery.refresh();
	  },
	  response => {
		console.error(response)
		$target.removeClass('button--disabled')
	  }
	)
});

$(document).ready(function() {
	if($( '[is="gallery"] .gallery-grid' ).length && $(window).width() < 768){
        $( '[is="gallery"] .gallery-grid' ).slick({
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            speed: 400,
            arrows: false,
            dots: false,
            draggable: true,
            infinite: true,
			mobileFirst: true,
			waitForAnimate: false,
            responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					},
				},
            ],
        });
    }    
})