import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import matchHeight from "jquery-match-height";

$(document).ready(function() {
    if($( '[is=carousel-post] .carousel-post__list' ).length){
        $( '[is=carousel-post] .carousel-post__list' ).slick({
            fade: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            pauseOnHover: false,
            speed: 400,
            arrows: true,
            dots: false,
            draggable: true,
            infinite: false,
            responsive: [
              {
                breakpoint: 1025,
                settings: "unslick",
              },
            ],
        });
    }    

    paddingCarousel();

    $(window).resize(function() {
        paddingCarousel();

        if($('.carousel-post__item--icon').length){
          $( '.carousel-post__item--icon' ).matchHeight();
        }  

        if($(window).width() >= 1026) {
          $( '[is=carousel-post] .carousel-post__list' ).slick({
            fade: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            pauseOnHover: false,
            speed: 400,
            arrows: true,
            dots: false,
            draggable: true,
            infinite: false,
            responsive: [
              {
                breakpoint: 1025,
                settings: "unslick",
              },
            ],
          });
        }
    });

    $( '.carousel-post__item--style1 .carousel-post__item-title' ).matchHeight();

    if($('.carousel-post__item--icon').length){
      $( '.carousel-post__item--icon' ).matchHeight();
    }    
});

function paddingCarousel() {
    if( $('.carousel-post__carousel').length ){
        if($(window).width() >= 576) {
            $('.carousel-post__carousel').css('marginRight', $('.carousel-width').offset().left * -1 );
        }
    }    
}