import $ from 'jquery';

$('document').ready(function() {
    $(document).mouseup(function(e) {
        var container = $('.sidebar.open');
    
        if (!container.is(e.target) && container.has(e.target).length === 0 && !$('.flatpickr-calendar').is(e.target) && $('.flatpickr-calendar').has(e.target).length === 0) {
            container.find('.close').click();
        }
    });
})