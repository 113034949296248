import $ from 'jquery';


$(document).ready(function() {
    if ($('.fixedCta').length) {
        $('footer').css('paddingBottom', $('.fixedCta').outerHeight());
    }
});

$(window).resize(function() {
    if ($('.fixedCta').length) {
        $('footer').css('paddingBottom', $('.fixedCta').outerHeight());
    }
});

// ICONE CONTATTI
$(document).ready(function(){
  $("#show-contacts").click(function(){
    $(".contact-btn__phone").toggleClass('move-up');
    $(".contact-btn__mail").toggleClass('move-up-2x');
    $(".contact-btn__booking").toggleClass('move-up-3x');

    $(".contact-btn__style-before").toggleClass('label');

  });
});

// TOGLI CLASSI CLICCANDO FUORI
$(document).mouseup(function(e) {

  var container = $("#show-contacts");

  if (!container.is(e.target) && container.has(e.target).length === 0) {
      
      $(".contact-btn__phone").removeClass('move-up');
      $(".contact-btn__mail").removeClass('move-up-2x');
      $(".contact-btn__booking").removeClass('move-up-3x');

      $(".contact-btn__style-before").removeClass('label');

  }

});

// TOGLI CLASSI SCROLLANDO
$(document).ready(function() {
  $(window).scroll(function(){

    $(".contact-btn__phone").removeClass('move-up');
    $(".contact-btn__mail").removeClass('move-up-2x');
    $(".contact-btn__booking").removeClass('move-up-3x');

    $(".contact-btn__style-before").removeClass('label');

      if ($(this).scrollTop() > 200) {

          $('.contact-btn').addClass('scroll-up');
      } else {
          $('.contact-btn').removeClass('scroll-up');
      }
  });
});

$(document).scroll(function(){
    console.log()
})

// POPUP MAIL
$(document).ready(function(){
  $("#show-contact__mail, #hide-popup-contact").click(function(e){
    // e.preventDefault();
    // $(".popup-contact__mail").toggleClass('d-flex');
    // $("body").toggleClass('overflow-hidden');
  });
});

// POPUP INFO CAMERE
$(document).ready(function(){
  $(".show-info-popup, .hide-popup-info").click(function(e){
    e.preventDefault();

    $('.popup-contact__info').find('input[id=_fc_camera]').val($.trim($(this).parents('.side-by-side__body').find('.side-by-side__title').text()));

    $(".popup-contact__info").toggleClass('d-flex');
    $("body").toggleClass('overflow-hidden');
  });
});
  

// POPUP INFO OFFERTE
$(document).ready(function(){
  $(".side-by-side.section-offerta .side-by-side__link, .hide-popup-offerte").click(function(e){
    e.preventDefault();

    $('.popup-contact__offerte').find('input[id=_fc_offerte]').val($.trim($(this).parents('.side-by-side__body').find('.side-by-side__title').text()));


    $(".popup-contact__offerte").toggleClass('d-flex');
    $("body").toggleClass('overflow-hidden');
  });
});

// TOGLI CLASSI CLICCANDO FUORI
// $(document).mouseup(function(e) {

//   var containerPopup1 = $("#container-1");

//   if (!containerPopup1.is(e.target) && containerPopup1.has(e.target).length === 0) {
    
//     $("#main-1").toggleClass('d-flex');
//     $("body").toggleClass('overflow-hidden');

  
//   }

//   var containerPopup2 = $("#container-2");

//   if (!containerPopup2.is(e.target) && containerPopup2.has(e.target).length === 0) {
    
//     $("#main-2").toggleClass('d-flex');
//     $("body").toggleClass('overflow-hidden');

  
//   }

//   var containerPopup3 = $("#container-3");

//   if (!containerPopup3.is(e.target) && containerPopup3.has(e.target).length === 0) {
    
//     $("#main-3").toggleClass('d-flex');
//     $("body").toggleClass('overflow-hidden');

  
//   }

// });