import $ from 'jquery';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

$(document).ready(function() {
	console.log(getCookie('popup'));
	if ( getCookie('popup') ) {    
        if( $( '.popup' ).length ) {
            $( '.popup' ).remove();
        }
    }else{
		setTimeout(() => {
			if( $( '.popup' ).length ) {
				$('.popup').addClass('open');
				disableBodyScroll(document.querySelector('.popup .content'));  
			}
		}, 1000);
	}

	$('.popup .close').click(function() {
		$('.popup').removeClass('open');
		setCookie( 'popup' , 1, 2);
		console.log('cookie creato');
		enableBodyScroll(document.querySelector('.popup .content')); 
	});
});

function setCookie(cname, cvalue, hour) {
    var d = new Date();
    d.setTime(d.getTime() + (hour * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
}


function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1);
		if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
	}
	return false;
}