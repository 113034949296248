import $ from 'jquery';
import matchHeight from "jquery-match-height";



$(window).on( 'load', function () {

    if( $( '.same-height' ).length ) {
        let matchHeightOptions = {
            byRow: true,
            property: 'height',
            target: null,
            remove: false,
        };
    
        $( '.same-height' ).matchHeight(matchHeightOptions);
    }

    if($('.side-by-side').length){        
        $('.side-by-side').each(function() {

            var elemenetSidebySide = $(this);
            if(elemenetSidebySide.find('.image').hasClass('right') && elemenetSidebySide.find('.side-by-side__figure').hasClass('strech-lg-right')){
                var position = elemenetSidebySide.find('.image').offset();
                elemenetSidebySide.find('.side-by-side__figure').css('left',position.left);
                var widthScreen = $(window).width();                
                var widthImage = widthScreen - 30 - position.left;
                if(widthScreen > 1450) {
                    var widthImage = widthImage - ((widthScreen - $('.container-fluid').outerWidth()) / 2);
                }
                elemenetSidebySide.find('.side-by-side__figure').css('width',widthImage);
            }

            if(elemenetSidebySide.find('#map').length) {
                var position = elemenetSidebySide.find('.right.maps').offset();
                var widthScreen = $(window).width();                
                var widthImage = widthScreen - 45 - position.left;
                if(widthScreen > 1450) {
                    var widthImage = widthImage - ((widthScreen - $('.container-fluid').outerWidth()) / 2);
                }
                elemenetSidebySide.find('#map').css('width',widthImage);
            }
        });

        $(window).resize(function() {
            $('.side-by-side').each(function() {
                var elemenetSidebySide = $(this);
                if(elemenetSidebySide.find('.image').hasClass('right') && elemenetSidebySide.find('.side-by-side__figure').hasClass('strech-lg-right')){
                    var position = elemenetSidebySide.find('.image').offset();
                    elemenetSidebySide.find('.side-by-side__figure').css('left',position.left);
                    var widthScreen = $(window).width();                
                    var widthImage = widthScreen - 30 - position.left;
                    if(widthScreen > 1450) {
                        var widthImage = widthImage - ((widthScreen - $('.container-fluid').outerWidth()) / 2);
                    }
                    elemenetSidebySide.find('.side-by-side__figure').css('width',widthImage);
                }

                if(elemenetSidebySide.find('#map').length) {
                    var position = elemenetSidebySide.find('.right.maps').offset();
                    var widthScreen = $(window).width();                
                    var widthImage = widthScreen - 45 - position.left;
                    if(widthScreen > 1450) {
                        var widthImage = widthImage - ((widthScreen - $('.container-fluid').outerWidth()) / 2);
                    }
                    elemenetSidebySide.find('#map').css('width',widthImage);
                }
            });
        });
    }
    
});