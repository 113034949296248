import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'
import feather from 'feather-icons'
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import installCE from 'document-register-element/pony'
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

$(document).ready(function () {
  feather.replace({
    'stroke-width': 1
  })
})

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  if(elementBottom > viewportTop && elementTop < viewportBottom) {
    return $(this);
  }
};

$(document).ready(function() { 

  $('.nav-hamburger').click(function() {
    $('.sidebar').removeClass('open');
  });

  $('a[href="#sidebar"]').click(function() {    
    if($('[is="menu-navigation"]').hasClass('menu-navigation--menuIsOpen')) {
      $('.hamburger').click();

      setTimeout(() => {
        $('.sidebar').toggleClass('open');
        
      }, 300);
    }else{
      $('.sidebar').toggleClass('open');
    }

    disableBodyScroll(document.querySelector('.sidebar'));    
  });

  $('.btn-maps').click(function() {    

      setTimeout(() => {
        $('.sidebar').toggleClass('open');
        
      }, 300);


    disableBodyScroll(document.querySelector('.sidebar'));    
  });
  

  $('.sidebar .close').click(function() {
    $('.sidebar').toggleClass('open');
    enableBodyScroll(document.querySelector('.sidebar'));
  });

  AOS.init({
    duration: 800
  });

  $('.ctaFixed__button').click(function() {
    $('.ctaFixed-popup').addClass('open');
    $('.ctaFixed').removeClass('show');
    disableBodyScroll(document.querySelector('.ctaFixed-popupContainer'));
  });

  $('.ctaFixed-popupClose').click(function() {
    $('.ctaFixed-popup').removeClass('open');
    // $('.ctaFixed').addClass('show');
    enableBodyScroll(document.querySelector('.ctaFixed-popupContainer'));
  });

  $('a[href="#popup"]').click(function(e) {    
    e.preventDefault();
    $('.ctaFixed-popup').addClass('open');
    disableBodyScroll(document.querySelector('.ctaFixed-popupContainer'));   
  });

  setTimeout(() => {
    $('body').css('opacity','1');
  }, 400);

  $('body.page-ctaFixed footer').css('paddingBottom',$('.ctaFixed').outerHeight());

  $(window).resize(function() {
    $('body.page-ctaFixed footer').css('paddingBottom',$('.ctaFixed').outerHeight());
  });

  // if ($(document).scrollTop() > 400) {
  //   if($('.ctaFixed').length && !$('.ctaFixed-popup').hasClass('open')){
  //     $('.ctaFixed').addClass('show');
  //   }
  // }else {
  //   if($('.ctaFixed').length){
  //     $('.ctaFixed').removeClass('show');
  //   }
  // }

  $(window).scroll(function() {
    // if ($(document).scrollTop() > 400) {
    //   if($('.ctaFixed').length && !$('.ctaFixed-popup').hasClass('open')){
    //     $('.ctaFixed').addClass('show');
    //   }
    // }else {
    //   if($('.ctaFixed').length){
    //     $('.ctaFixed').removeClass('show');
    //   }
    // }
  });  
});

$(document).ready(function() {

  const primoElemento = $('#estate1');
  const secondoElemento = $('#inverno1');
  const primoPulsante = $('a#estate');
  const secondoPulsante = $('a#inverno');

  secondoElemento.hide();

  primoPulsante.on('click', function() {
    secondoElemento.fadeOut('200');
    setTimeout(() => {
      primoElemento.fadeIn('200');

    }, 370);
  });

  secondoPulsante.on('click', function() {
    primoElemento.fadeOut('200');

    setTimeout(() => {
      secondoElemento.fadeIn('200');

    }, 370);
  });
});

// MOVIEMNTO RIQUADRI IMMAGINI ALLO SCROLL

// $(document).ready(function() {
//   // Dichiara l'elemento div che vuoi muovere
//   var $elementoDiv = $('.figure-shape');

//   // Dichiara l'elemento di riferimento
//   var $elementoRiferimento = $('.side-by-side__image');

//   $(window).scroll(function() {
//     // Ottieni la posizione dell'elemento di riferimento rispetto alla finestra
//     var posizioneFinestra = $(window).scrollTop();
//     var posizioneElemento = $elementoRiferimento.offset().top;

//     // Calcola la distanza tra l'elemento di riferimento e la finestra
//     var distanza = posizioneElemento - posizioneFinestra;

//     // Muovi l'elemento div lateralmente
//     $elementoDiv.css('margin-left', distanza);
//   });
// });

document.addEventListener('DOMContentLoaded', function () {
  // Ottiene tutti i link della pagina
  var links = document.getElementsByTagName('a');

  // Ottiene il dominio attuale
  var currentDomain = window.location.hostname;

  for (var i = 0; i < links.length; i++) {
    var link = links[i];
    var href = link.getAttribute('href');
    var domain = link.hostname;

    // Controlla se il link è esterno e non è un link mailto: o tel:
    if (href != null && domain !== currentDomain && !href.startsWith('mailto:') && !href.startsWith('tel:')) {
      // Imposta target="_blank" e rel="noopener noreferrer" per i link esterni
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    }
  }
});
