import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';

$(document).ready(function() {
	if($('[is="table"]').length){
        $('.list-sections li').click(function() {
            $('.list-sections li.active').removeClass('active');
            $(this).addClass('active');
            $('.content-tabs .tab.active').removeClass('active');
            $(this).parent().parent().parent().find('.baetta').css('left',$(this).position().left);

            var tab_id = $(this).data('id');

            $('.content-tabs .tab[data-id="' + tab_id + '"]').addClass('active');
        });

        Scrollbar.initAll({
            alwaysShowTracks: true,
        });
    }
});